.header-logo-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.sider-logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.background-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-image: url("../assets/drgn_base.jpg"); /* Replace 'your-image.jpg' with your image path */
  background-size: cover;
  background-position: center;
  z-index: -1; /* Make sure the background stays behind other content */
}

.background-image::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.contents {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Enable vertical scrolling */
  z-index: 1; /* Ensure content appears above the background */
  text-align: center;
  color: white; /* Adjust text color to ensure readability */
}

.sider-change-language-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sider-connect-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-button-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
